import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  Divider,
  DialogActions,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { buildSku } from '../../../../utils/ProductUtils'
import FluidProductImage from '../../FluidProductImage'
import { formatPrice } from '../../../../utils/utils'
import { renderImageUrl } from '../../../../lib/imageurl_api'
import { getThumbnailReplacementType } from '../../../../utils/UrlUtils'
import { trackEvent } from '../../../../utils/ArtboxoneTracking'
import { CartItem, ProductData, ProductImage } from '../../../../types'
import BuyButtonPage from '../BuyButtonUpsellingPage'
import ListItem from './ListItem'
import './style.css'

const useStyles = makeStyles(theme => ({
  noLineBreak: {
    whiteSpace: 'nowrap',
  },
  image: {
    position: 'relative',
    left: '10px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
      minHeight: 400,
      left: 'unset',
      right: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      right: 50,
      left: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      right: 'unset',
      left: 'unset',
    },
  },
}))

interface ConfigurationDialogProps {
  open: boolean
  setOpen: (state: boolean) => void
  productData: ProductData
  imageUrl: string
  priceCountry: string
  chosenMaterial: Record<string, any>
  materials: Record<string, any>[]
}

const ConfigurationDialog: React.FC<ConfigurationDialogProps> = ({
  open,
  setOpen,
  productData,
  imageUrl,
  priceCountry,
  chosenMaterial,
  materials,
}) => {
  const [selectedMaterialVariant, setSelectedMaterialVariant] =
    useState(chosenMaterial)
  const [imageUrlsProps, setImageUrlsProps] = useState(imageUrl)
  const [productDataProps] = useState(productData)

  useEffect(() => {
    setSelectedMaterialVariant(chosenMaterial)
  }, [chosenMaterial])

  const { t } = useTranslation('translation')
  const buttonText = t('Weiter')
  const classes = useStyles()

  const cartItem: CartItem = {
    motiveId: productDataProps.id,
    material: selectedMaterialVariant.materialgroup,
    materialIId: selectedMaterialVariant.iid,
    count: 1,
    inserted: 0,
    ratio: productDataProps.ratio,
  }

  const formattedPriceForSelectedVariant = useMemo(() => {
    if (selectedMaterialVariant && priceCountry) {
      const prices = selectedMaterialVariant.price
      const priceForSelectedVariant = prices?.[priceCountry]

      return priceForSelectedVariant
        ? formatPrice(priceForSelectedVariant)
        : undefined
    }
  }, [selectedMaterialVariant, priceCountry])

  const updateSelectedVariant = (variant: Record<string, any>) => {
    trackEvent({
      category: 'pes',
      action: 'DialogChangeMaterial',
      label: buildSku(variant.material, cartItem.motiveId),
    })

    const thumbnail: ProductImage = {
      template: variant.images.small,
      replacement: getThumbnailReplacementType(variant.material),
    }

    const imageUrl = renderImageUrl({
      image: thumbnail,
      productData: productDataProps,
    })

    thumbnail.template && imageUrl && setImageUrlsProps(imageUrl)

    setSelectedMaterialVariant(variant)
  }

  const dialogOnClose = () => {
    trackEvent({
      category: 'pes',
      action: 'DialogChangeMaterialOnClose',
      label: buildSku(selectedMaterialVariant.material, cartItem.motiveId),
    })
    setOpen(false)
    setSelectedMaterialVariant(chosenMaterial)
    setImageUrlsProps(imageUrl)
  }

  return (
    <Dialog
      className="ups-dialog"
      maxWidth="lg"
      onClose={dialogOnClose}
      open={open}
    >
      <IconButton
        aria-label="close"
        className="ups-dialog__close-icon"
        onClick={dialogOnClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="ups-dialog__content">
        <div className="ups-dialog__image">
          <FluidProductImage
            className="productConfiguration"
            imageUrl={imageUrlsProps}
            productData={productDataProps}
          />
        </div>
        <div className="ups-dialog__list">
          <Box>
            <h3 style={{ margin: 0 }}>{productDataProps.name}</h3>
            <span>{selectedMaterialVariant.name}</span>
          </Box>
          <Grid container>
            <Box display="contents">
              {materials
                .map((material, index) => {
                  const variants: Record<string, any>[] = Object.values(
                    material.variantsPerColor,
                  )
                  const defaultVariant = variants[0]
                  const defaultPrice = defaultVariant?.price[priceCountry]

                  const defaultSelectedVariantIndex = variants.findIndex(
                    variant => variant.material === chosenMaterial.material,
                  )

                  return (
                    <Grid item key={`${defaultPrice}${index}`} xs={12}>
                      <ListItem
                        defaultSelectedVariantIndex={
                          defaultSelectedVariantIndex
                        }
                        index={index}
                        isChecked={
                          defaultVariant.materialgroup ===
                          selectedMaterialVariant.materialgroup
                        }
                        onListItemClick={updateSelectedVariant}
                        priceCountry={priceCountry}
                        text={t(material.name)}
                        variants={material.variantsPerColor}
                      />
                      <Divider />
                    </Grid>
                  )
                })
                .sort((a, b) => a.key - b.key)}
            </Box>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className="ups-dialog__action-area">
        <Box display="flex" flexDirection="column" mr={2}>
          {formattedPriceForSelectedVariant && (
            <Box>
              <span>{`${t('current_total_price')}: `}</span>
              <span
                className={classes.noLineBreak}
                dangerouslySetInnerHTML={{
                  __html: ` ${formattedPriceForSelectedVariant}`,
                }}
                style={{ fontWeight: 'bold' }}
              />
            </Box>
          )}
          <span>{t('including_tax_and_shipping')}</span>
        </Box>
        <BuyButtonPage
          cartItem={cartItem}
          hideIcon
          material={selectedMaterialVariant}
          outOfStock={selectedMaterialVariant.out_of_stock}
          productData={productDataProps}
          text={buttonText}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ConfigurationDialog
