import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '../../../../utils/utils'
import './ListItem.css'

interface ContainerProps {
  text: string
  isChecked: boolean
  index: number
  priceCountry: string
  variants: { [key: string]: Record<string, any> }
  onListItemClick: (variant: Record<string, any>) => void
  defaultSelectedVariantIndex: number
}

interface ComponentProps {
  checked: ContainerProps['isChecked']
  colorKeys: string[]
  id: string
  index: ContainerProps['index']
  text: ContainerProps['text']
  onListItemClick: ContainerProps['onListItemClick']
  onColorChange: (colorName: string) => void
  price?: string
  selectedColor: string
}

interface ColorPickerProps {
  index: number
  colorKeys: string[]
  selectedColorKey?: string
  setSelectedColorKey: (key: string) => void
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  colorKeys,
  index,
  selectedColorKey,
  setSelectedColorKey,
}) => {
  const { t } = useTranslation('translation')

  const radioGroupName = `radio-buttons-group-${index}`

  return (
    <div className="list-item__color-picker">
      <label>{t('choose_a_color')}</label>
      {colorKeys.map((name, index) => (
        <div className="color-picker__option" key={`${name}-${index}`}>
          <input
            checked={name === selectedColorKey}
            id={name}
            name={radioGroupName}
            onClick={() => setSelectedColorKey(name)}
            type="radio"
            value={name}
          />
          <label htmlFor={name}>{t(name)}</label>
        </div>
      ))}
    </div>
  )
}

const Component: React.FC<ComponentProps> = ({
  colorKeys,
  id,
  index,
  text,
  checked,
  onColorChange,
  onListItemClick,
  price,
  selectedColor,
}) => {
  const hasMultipleColors = useMemo(() => colorKeys.length > 1, [colorKeys])

  return (
    <div
      className={clsx(
        'dialog__list-item',
        !hasMultipleColors && 'dialog__list-item--wider',
      )}
    >
      <input
        checked={checked}
        id={id}
        name="material-group"
        onClick={onListItemClick}
        type="radio"
        value={text}
      />
      <span className="list-item__text" onClick={onListItemClick}>
        {text}
      </span>
      {price && (
        <span
          className="list-item__price"
          dangerouslySetInnerHTML={{
            __html: price,
          }}
          onClick={onListItemClick}
        />
      )}
      {hasMultipleColors && (
        <ColorPicker
          colorKeys={colorKeys}
          index={index}
          selectedColorKey={checked ? selectedColor : undefined}
          setSelectedColorKey={onColorChange}
        />
      )}
    </div>
  )
}

const Container: React.FC<ContainerProps> = ({
  index,
  isChecked,
  text,
  variants,
  priceCountry,
  onListItemClick,
  defaultSelectedVariantIndex,
}) => {
  const positiveDefaultIndex = useMemo(
    () => (defaultSelectedVariantIndex > 0 ? defaultSelectedVariantIndex : 0),
    [defaultSelectedVariantIndex],
  )
  const colorKeys = useMemo(() => Object.keys(variants), [variants])

  const firstKey = useMemo(
    () => colorKeys[positiveDefaultIndex],
    [colorKeys, positiveDefaultIndex],
  )

  const [selectedVariant, setSelectedVariant] = useState({
    key: firstKey,
    value: variants[firstKey],
  })

  const id = text.split(' ').join('_')

  const itemPrice = useMemo(() => {
    const price = selectedVariant?.value.price

    if (price[priceCountry]) {
      return formatPrice(price[priceCountry])
    }
  }, [selectedVariant, priceCountry])

  const handleItemClick = () => onListItemClick(selectedVariant.value)

  const handleColorChange = (key: string) => {
    setSelectedVariant({
      key,
      value: variants[key],
    })

    onListItemClick(variants[key])
  }

  return (
    <Component
      checked={isChecked}
      colorKeys={colorKeys}
      id={id}
      index={index}
      onColorChange={handleColorChange}
      onListItemClick={handleItemClick}
      price={itemPrice}
      selectedColor={selectedVariant.key}
      text={text}
    />
  )
}

export default Container
