import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import BrushIcon from '@mui/icons-material/Brush'
import { ParsedSku, ProductData, ProductMaterial } from '../../../types'
import { buildPesUrl } from '../../../utils/UrlUtils'
import { navigate } from 'gatsby'
import { trackEvent } from '../../../utils/ArtboxoneTracking'

function getModalStyle() {
  return {
    margin: 'auto',
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

interface CustomizeProductProps {
  text: string | undefined
  productInformation: ParsedSku
  material: string
  productData: ProductData
  currentMaterial: ProductMaterial
}

const CustomizeProduct: React.FC<CustomizeProductProps> = ({
  text,
  productInformation,
  productData,
  material,
  currentMaterial,
}) => {
  if (text === undefined) {
    text = ''
  }

  const { t } = useTranslation('translation')

  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)
  const [enteredText, setEnteredText] = React.useState(text)

  const handleOpen = (event: any) => {
    trackEvent({
      category: 'pes',
      action: 'customization-dialog',
      label: 'open',
    })
    setOpen(true)
  }

  const handleClose = (event: any) => {
    setOpen(false)
    trackEvent({
      category: 'pes',
      action: 'customization-dialog',
      label: 'close',
    })
  }

  const handleApply = (event: any) => {
    let templateUrl = buildPesUrl(
      material,
      '%s',
      productInformation.motiveId,
      productInformation.slug,
      productData.ratio,
      enteredText,
    )

    trackEvent({
      category: 'pes',
      action: 'customization-dialog',
      label: 'apply',
      value: 'enteredText',
    })
    templateUrl = templateUrl.replace('%s', currentMaterial.url_slug)

    navigate(templateUrl)
    setOpen(false)
  }

  const handleChange = (event: any) => {
    const query = '' + event.target.value
    setEnteredText(query)
  }

  return (
    <div>
      <Button
        className="cta_pes_button"
        color="primary"
        onClick={handleOpen}
        variant="outlined"
      >
        <BrushIcon /> {t('Individualisieren')}
      </Button>
      <Modal
        aria-describedby="filter-modal-description"
        aria-labelledby="filter-modal-title"
        onClose={handleClose}
        open={open}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={classes.paper} style={modalStyle}>
          <h2 id="filter-modal-title">{t('Wunschtext')}</h2>
          <p id="simple-modal-description">
            {t('Lass deinen Wunschtext aufdrucken.')}
          </p>

          <div className="row">
            <div className="col d-flex justify-content-center">
              <TextField
                id="standard-basic"
                label={t('Wunschtext')}
                onChange={handleChange}
                value={enteredText}
              />
            </div>
          </div>

          <div className="row">
            <div className="col d-flex justify-content-center">
              <Button
                color="primary"
                onClick={handleApply}
                style={{ marginTop: `24px` }}
                variant="contained"
              >
                <CloseIcon /> {t('Schließen')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CustomizeProduct
