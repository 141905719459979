import React, { ReactElement } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import {
  ProductMaterial,
  ParsedSku,
  CartItem,
  ProductData,
  GlobalSale,
  Session,
} from '../../../types'
import MaterialSelect from './MaterialSelect'
import Grid from '@mui/material/Grid'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import BrushIcon from '@mui/icons-material/Brush'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import BuyButtonBar from './BuyButtonStatusBar'
import BuyButtonPage from './BuyButtonUpsellingPage'
import { formatPrice } from '../../../utils/utils'
import DeliveryTimeInformation from '../DeliveryTimeInformation'
import MaterialGroupSelect from './MaterialGroupSelect'
import {
  getCartCountry,
  getPriceCountry,
} from '../../../utils/CountrySelection'
import { getFreeShipping } from '../../../utils/CartUtils'
import EvilSpan from '../../generic/EvilSpan'
import RatioSelect from './RatioSelect'
import { useTranslation } from 'react-i18next'
import CustomizeProduct from './CustomizeProduct'
import { getCrosslinkProducts } from '../../../utils/ProductUtils'
import { useSale } from '../../../lib/global/sale_hook'
import {env} from '../../../../environment'
import { getSession } from '../../../lib/aref'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '.configuration--button-classic': {
        display: "block"
      }
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }),
)

interface ProductConfiguratorProps {
  materials: ProductMaterial[]
  productInformation: ParsedSku
  material: string
  productData: ProductData
  customText: string | undefined
  crosslinkButton: any
  openConfiguratorDialogButton: ReactElement
}

const ProductConfigurator: React.FC<ProductConfiguratorProps> = ({
  materials,
  productInformation,
  material,
  productData,
  customText,
  crosslinkButton,
  openConfiguratorDialogButton,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('translation')

  const currentMaterial = materials
    .filter((materialData: ProductMaterial) => {
      return materialData.iid == productInformation.materialIId
    })
    .pop()

  const isCustomizable = false // currentMaterial?.customizable && productData.customizable
  const priceCountry = getPriceCountry(getCartCountry())

  let price = currentMaterial?.price[priceCountry]
  const priceUvp =
    typeof currentMaterial?.priceUvp !== 'undefined'
      ? currentMaterial?.priceUvp[priceCountry]
      : price

  let salePrice = 0.0
  const buttonText = t('In den Warenkorb')
  let saleText = ''
  const sale: GlobalSale | undefined = useSale()

  if (sale !== undefined && price !== undefined) {
    const salePercent = sale.sale
    salePrice = (price / 100) * (100 - salePercent)
    salePrice = Math.round((salePrice + Number.EPSILON) * 100) / 100
    saleText = '' + sale.text
  } else if (price != priceUvp) {
    salePrice = price ? price : 0.0
    price = priceUvp
  }

  let formattedPrice = ''

  if (typeof price !== 'undefined') {
    formattedPrice = formatPrice(price)

    if (saleText != '') {
      formattedPrice += ' *'
    }
  }

  const outOfStock = currentMaterial?.out_of_stock

  const cartItem: CartItem = {
    motiveId: productInformation.motiveId,
    material: material,
    materialIId: productInformation.materialIId,
    count: 1,
    inserted: 0,
    ratio: productData.ratio,
    customText: customText,
  }

  let showCrosslinkButton = false
  let optionCount = 0
  const display = getCrosslinkProducts(productData, material, false)

  if (display !== undefined) {
    display.map((r: any) => {
      optionCount++
    })

    showCrosslinkButton = optionCount >= 1
  }

  if (
    [
      'wallcalendar',
      'foldingcalendar',
      'deskcalendar',
      'squarecalendar',
      'familyplaner',
    ].indexOf(material) >= 0
  ) {
    showCrosslinkButton = true
  }

  const locale = env.getLocale()
  const session: Session = getSession()

  // if (locale == 'de-DE') {
  //   showUpsellingButton = session.abtest == 'variant'
  // }

  const showClassicBuyButton = openConfiguratorDialogButton ? 'none' : 'block'

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      direction="column"
      spacing={2}
    >
      <Grid item xs={12}>
        {salePrice > 0 && price && price > 0 && (
          <>
            <span
              className="pesPriceSmall"
              dangerouslySetInnerHTML={{ __html: formatPrice(price) }}
              style={{
                textAlign: `center`,
                textDecoration: `line-through`,
                color: `gray`,
              }}
            ></span>
            <br />
            <span
              className="pesPrice"
              dangerouslySetInnerHTML={{ __html: formatPrice(salePrice) }}
              style={{ textAlign: `center`, color: `red` }}
            ></span>
          </>
        )}

        {salePrice == 0 && price && price > 0 && (
          <>
            <span
              className="pesPrice"
              dangerouslySetInnerHTML={{ __html: formatPrice(price) }}
              style={{ textAlign: `center` }}
            ></span>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <MaterialGroupSelect
          currentMaterial={currentMaterial}
          material={material}
          materials={materials}
          productData={productData}
          productInformation={productInformation}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialSelect
          currentMaterial={currentMaterial}
          material={material}
          materials={materials}
          productData={productData}
          productInformation={productInformation}
        />
      </Grid>

      <Grid className="cta_pes_outer" data-test="ups-add2cart" item xs={12}>
        {openConfiguratorDialogButton &&
          <div
            className="configuration--dialog-button"
          >
            {openConfiguratorDialogButton}
          </div>
        }

        <div className="configuration--button-classic" style={{ display: `${showClassicBuyButton}` }}>
          <BuyButtonPage
            cartItem={cartItem}
            material={currentMaterial}
            outOfStock={outOfStock}
            productData={productData}
            text={buttonText}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <p style={{ textAlign: `center`, fontSize: `0.8rem` }}>
          {t('Inkl. MwSt., zzgl. Versand')}
          <br />
          <DeliveryTimeInformation materialIId={currentMaterial?.iid} />
        </p>
      </Grid>
      <Grid item xs={12}>
        <ul className="hideBulletinPoints">
          {saleText !== '' && (
            <li>
              <LoyaltyIcon /> * {saleText}
            </li>
          )}
          <li>
            <BrushIcon /> {t('individuell für dich produziert')}
          </li>
          <li>
            <LocalShippingIcon />{' '}
            <EvilSpan
              content={
                t('Versandkostenfrei ab') +
                ' ' +
                formatPrice(getFreeShipping(priceCountry))
              }
            />
          </li>
          <li>
            <InsertEmoticonIcon /> {t('Jeder Kauf unterstützt einen Künstler')}
          </li>
        </ul>
      </Grid>

      {isCustomizable && (
        <Grid className="cta_pes_outer" item xs={12}>
          <CustomizeProduct
            currentMaterial={currentMaterial}
            material={material}
            productData={productData}
            productInformation={productInformation}
            text={customText}
          />
        </Grid>
      )}

      <Grid className="cta_pes_outer" item xs={12}>
        <RatioSelect
          currentMaterial={currentMaterial}
          materialgroup={material}
          productData={productData}
        />
      </Grid>
      <Grid className="cta_pes_outer" item xs={12}>
        {showCrosslinkButton === true && crosslinkButton}
        {/* <CrosslinkMaterialSelect
          productData={productData}
          currentMaterial={material}
        /> */}
      </Grid>
    </Grid>
  )
}

export default ProductConfigurator
